import React from 'react'
import  BottomTab from '../Components/BottomTab';
import Header from '../Components/Header';
export default function Home() {
  
  return (
    <div>
      <Header title={`Chai Tapri`}/>

      
      <BottomTab/>
    </div>
  )
}
